
import { mdiInformationOutline } from "@mdi/js"
import { defineComponent, onMounted, ref, watch } from "vue"

export default defineComponent({
    props: {
        label: {
            type: String,
            default: null
        },
        smallLabel: {
            type: String,
            default: null
        },
        placeholder: {
            type: String,
            default: ""
        },
        name: {
            type: String,
            required: true
        },
        autocomplete: {
            type: String,
            default: ""
        },
        type: {
            type: String,
            default: "text"
        },
        modelValue: {
            type: String,
            default: ""
        },
        disabled: {
            type: Boolean,
            default: false
        },
        lightdisabled: {
            type: Boolean,
            default: false
        },
        readonly: {
            type: Boolean,
            default: false
        },
        textarea: {
            type: Boolean,
            default: false
        },
        showErrorMessage: {
            type: Boolean,
            default: false
        },
        errorMessage: {
            type: String,
            default: ""
        },
        information: {
            type: String,
            default: ""
        },
        leadingIcon: {
            type: String,
            default: ""
        },
        trailingIcon: {
            type: String,
            default: ""
        }
    },
    emits: ["update:modelValue"],
    setup(props, { emit }) {
        const inputValue = ref("")

        watch(() => props.modelValue, (newVal) => {
            inputValue.value = newVal
        })
        watch(inputValue, (newVal) => {
            emit("update:modelValue", newVal)
        })

        
        onMounted(() => {
            inputValue.value = props.modelValue
        })

        return {
            inputValue,
            mdiInformationOutline
        }
    },
    components: {
        
    }
})
