
import { defineComponent, computed, watch } from "vue"
import HeaderItem from "@/components/HeaderItem.vue"

export default defineComponent({
    setup() {
        return {}
    },
    components: {
        HeaderItem
    }
})
