import { createRouter, createWebHistory, RouteRecordRaw } from "vue-router"
import MainView from "../views/MainView.vue"
import MsgSentView from "../views/MsgSentView.vue"

const routes: Array<RouteRecordRaw> = [
    {
        path: "/",
        name: "home",
        component: MainView
    },
    {
        path: "/msgsent",
        name: "msgsent",
        component: MsgSentView
    }
]

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes
})

export default router
