import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, vModelDynamic as _vModelDynamic, withDirectives as _withDirectives } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "flex gap-2"
}
const _hoisted_2 = ["for"]
const _hoisted_3 = { class: "relative rounded-md w-full" }
const _hoisted_4 = ["type", "name", "id", "autocomplete", "readonly", "disabled", "placeholder"]

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", null, [
    (_ctx.label)
      ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
          _createElementVNode("label", {
            for: _ctx.name,
            class: "block text-sm mb-3"
          }, _toDisplayString(_ctx.label), 9, _hoisted_2)
        ]))
      : _createCommentVNode("", true),
    _createElementVNode("div", _hoisted_3, [
      _withDirectives(_createElementVNode("input", {
        class: "disabled:opacity-50 focus:ring-opacity-25 focus:border-primary-normal block w-full h-10 border border-gray-300",
        "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.inputValue) = $event)),
        type: _ctx.type,
        name: _ctx.name,
        id: _ctx.name,
        autocomplete: _ctx.autocomplete,
        readonly: _ctx.readonly,
        disabled: _ctx.disabled || _ctx.lightdisabled,
        placeholder: _ctx.placeholder
      }, null, 8, _hoisted_4), [
        [
          _vModelDynamic,
          _ctx.inputValue,
          void 0,
          { trim: true }
        ]
      ])
    ])
  ]))
}