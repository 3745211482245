<template>
    <div class="home flex justify-center mt-32 mb-96">
       <div class="text-3xl">
           <p class="">Wir haben deine Nachricht erhalten.</p>
           <br />
           <p class="text-primary-normal text-xl">Wir melden uns innerhalb von 24 Stunden telefonisch bei dir.</p>
           <p class="text-sm">(Außer an Wochenenden und Feiertagen)</p>
       </div>
    </div>

    <div class="bg-white flex flex-col items-center gap-8">
        <div class="flex text-sm gap-8 cursor-pointer">
            <a href="https://business-luchs.de/impressum.html">Impressum</a>
            <a href="https://business-luchs.de/datenschutz.html">Datenschutz</a>
            <a href="https://business-luchs.de/agb.html">AGB</a>
        </div>
        <div class="text-sm mb-8">
            <p>2023 Business Luchs</p>
        </div>
    </div>
</template>

<script setup lang="ts">




</script>
